.user-dashboard {
  display: flex;
  flex-direction: column;

  padding: 2rem;

  height: calc(100% - 4rem);
}

.user-dashboard li {
  margin-bottom: 1rem;
}

.user-dashboard li a {
  text-decoration: underline;
}

.user-dashboard .form {
  position: relative;
}

.user-dashboard .processing-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  background: var(--color-overlay-background);
}

.user-dashboard .processing-overlay h3 {
  margin-top: 0;
}

.user-dashboard .progress-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.user-dashboard .processing-overlay strong {
  position: relative;
  top: 12px;
}

.user-dashboard .cancel-button {
  position: absolute;
  bottom: 12px;
  right: 3px;
}

.user-dashboard .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.user-dashboard .classes-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-dashboard .button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.user-dashboard .subscriptions {
  flex: 1;
}

.user-dashboard .subscriptions .mdc-list-item {
  padding-bottom: 1rem;
}
