.articles  ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  list-style: none;
  margin: 0;
  padding: 0;
}

.articles li {
    margin: 2rem;
    width: 480px;
    background-color: var(--page-color-background-color);
    padding: 1rem;
    text-align: center;
    font-size: 2rem;
    font-family: var(--font-bold);
    text-transform: uppercase;
    position: relative;
}

.articles img {
    width: 100%;
}

.articles a {
    position: absolute;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;

    padding: 1rem 2rem;
    background: rgba(255, 255, 255, .8);

    display: flex;
    justify-content: center;
    align-items: center;
}