#activation-codes-table .applied .material-icons {
  color: green;
}

#activation-codes-table a {
  text-decoration: underline;
}

#activation-codes-table .mdc-list-item__text {
  padding-right: 1rem;
}
