.inline-content {
  padding: 0rem 1rem;
  word-break: break-word;
}

.inline-content .page:first-of-type {
  padding-top: 2rem;
}

.inline-content .page:not(:first-of-type) {
  padding-top: 10rem;
}

.inline-content .page {
  position: relative;
  display: flex;
    flex-direction: column;
    align-items: center;
}

.inline-content .page:nth-child(odd):before {
  content: '';
  display: block;
  position: absolute;
  top: 8rem;
  right: -2rem;
  bottom: -4rem;
  left: -2rem;
  z-index: -1;

  background: var(--page-color-odd-background-color);
}

.inline-content .page h2 {
  display: flex;
  align-content: center;
  width: 50rem;
  max-width: 100%;

  border-bottom: 1px solid var(--color-border);
  padding-bottom: 1rem;
}

.inline-content .page h2 .material-icons {
  position: relative;
  top: 2px;
}

.inline-content .markdown {
  /* min-height: 50vh; */
  max-width: 60rem;
  margin: auto;
}

.inline-content .assignment-button {
  margin-top: 2rem;
  text-align: center;
}
