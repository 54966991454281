.activation-code-detail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: var(--color-overlay-background);
}

.activation-code-detail .mdc-list {
  padding-bottom: 4rem;
}

.activation-code-detail .mdc-list-item {
  padding: 1rem;
}

.activation-code-detail .mdc-list-item__text {
  width: 100%;
}

.activation-code-detail .mdc-list-item__text > div {
  display: flex;
}

.activation-code-detail .flex {
  flex: 1;
}
