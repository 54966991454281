.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;

  background: var(--color-overlay-background);
}

.modal > button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1;
}

.modal .modal-contents {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
