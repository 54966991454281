.about-us  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .about-us li {
      margin: 2rem;
      max-width: 480px;
      text-align: center;
      font-size: 4rem;
      font-weight: 700;    
      text-transform: uppercase;
      position: relative;
  }

  
  .about-us img {
      width: 100%;
  }
  
  .about-us a.text-link {
      position: absolute;
      top: 0rem;
      right: 0rem;
      bottom: 1rem;
      left: 0rem;
  
      padding: 0rem 2rem;
      color: var(--color-text-light);
      background: rgba(23, 127, 98, .4);
      opacity: 1;
      transition: opacity 300ms linear;
  
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: var(--font-bold);
  }
  
  .about-us a.text-link:hover {
      background: rgba(255, 255, 255, 0);
      opacity: 0;
  }