.table-of-contents {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 4;
}

.table-of-contents .mdc-menu {
  min-width: 12rem;
}

.table-of-contents .arrow {
  color: var(--mdc-theme-secondary);
  position: absolute;
  right: 1rem;
}

.table-of-contents .up {
  top: 4rem;
}

.table-of-contents .down {
  bottom: 1rem;
}

.table-of-contents .mdc-list-item {
  display: flex;
  align-content: center;
}

.table-of-contents .mdc-list-item .material-icons {
  margin-right: 1rem;
}
